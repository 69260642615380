import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Button from '../components/Button';
import Link from '../components/Link';

const nsBase = 'page';
const ns = `${ nsBase }-styleguide`;

const Styleguide = () => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	const pageStyles = {
		paddingTop: 360,
		paddingBottom: 190,
		minHeight: '100vh',
		background: '#2A252B'
	};

	return (
		<div className={rootClassnames} style={pageStyles}>
			<SEO title={'styleguide'} />
			<div className={'container-fluid'}>
				<h2>Headings</h2>
				<br />
				<h1>H1: Heading 1</h1>
				<br />
				<h2>H2: Heading 2</h2>
				<br />
				<h3>H3: Heading 3</h3>
				<br />
				<br />
				<h2>Buttons</h2>
				<br />
				<Button>Default</Button>
				<br />
				<Button variant={'white'}>Default</Button>
				<br />
				<br />
				<h2>Links</h2>
				<Link href={'/'}>Default</Link>
				<br />
				<br />
				<Link href={'/'} variant={'underline'}>Underline</Link>
			</div>
		</div>
	);
};

export default Styleguide;
