import React from 'react';
import classNames from 'classnames';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import TransitionLink from 'gatsby-plugin-transition-link';

import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../functions';
import { fadeAnimationExit, fadeAnimationEntry } from '../../functions/page-transitions';

/*
- href prop wll create a regular a-tag but still with button classes
- disabled prop will add 'tabindex' of -1 and 'aria-disabled' of 'true' for accessibility
- variant - prop will add a modifier to classname
*/

const nsBase = 'component';
const ns = `button-${ nsBase }`;

const Button = React.forwardRef(
	(
		{
			active,
			className,
			variant,
			type,
			disabled,
			as,
			customFade,
			...props
		},
		ref
	) => {
		// define button classes
		const rootClassnames = classNames(
			className,
			`${ ns }`,
			`${ ns }--${ variant }`,
			active && 'active'
		);

		/* eslint-disable */
		if (props.href && isExternalUrl(props.href)) {
			return (
				<SafeAnchor
					{...props}
					as={as}
					ref={ref}
					className={classNames(rootClassnames, props.disabled && 'disabled')}
				/>
			);
		}

		if (props.href && !isExternalUrl(props.href)) {
			if (customFade) {
				return (
					<TransitionLink
						{...props}
						disabled={disabled}
						exit={{
							length: 2.25,
							trigger: ({ exit, node }) => {
								fadeAnimationExit(node);
							},
							state: { test: 'exit state' },
						}}
						entry={{
							delay: 0.75,
							trigger: () => {
								fadeAnimationEntry();
							}
						}}
						to={props.href || '/'}
						ref={ref}
						className={`${ rootClassnames } ${ ns }__link`}
					>
						{props.children}
					</TransitionLink>
				);
			}

			return (
				<AniLink
					fade
					{...props}
					disabled={disabled}
					to={props.href || '/'}
					ref={ref}
					className={`${ rootClassnames } ${ ns }__link`}
				>
					{props.children}
				</AniLink>
			)
		}

		if (ref) {
			props.ref = ref;
		}

		if (!as) {
			props.type = type;
		}

		const Component = as || 'button';
		return <Component {...props} className={rootClassnames} />;
		/* eslint-enable */
	}
);

Button.defaultProps = {
	variant: 'default',
	active: false,
	disabled: false,
	type: 'button',
	customFade: true
};

export default Button;
